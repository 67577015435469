import { useEffect, useState } from "react";
import classNames from "classnames";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import HamburgerIcon from "./HamburgerIcon";
import ChevronRight from "./ChevronRight";
import CloseIcon from "./CloseIcon";

const Header = ({link}) => {
  const [isScroll, setIsScroll] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 112) {
      setIsScroll(true);
    } else {
      setIsScroll(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {passive: true});

    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  useEffect(() => {
    if (showMenu) {
      document.body.classList.add('overflow-y-hidden');
    } else {
      document.body.classList.remove('overflow-y-hidden');
    }
  }, [showMenu]);

  return (
    <header className={classNames('fixed top-0 left-0 w-full px-6 transition-all duration-200 flex justify-center z-40', {'h-28': !isScroll, 'h-20 bg-white': isScroll, 'bg-white': showMenu})}>
      <div className="container flex flex-row items-center justify-between">
        <LazyLoadImage
          alt="logo"
          src={isScroll || showMenu ? '/assets/logo-blue.png' : '/assets/logo-white.png'}
          className="w-24 md:w-36 object-contain"
        />
        <div className="hidden md:flex flex-row items-center">
          <a className={classNames('px-2.5 text-header transition-all duration-200 hover:text-endeavour', {'text-white': !isScroll, 'text-black': isScroll})} href="#home">Home</a>
          <a className={classNames('px-2.5 text-header transition-all duration-200 hover:text-endeavour', {'text-white': !isScroll, 'text-black': isScroll})} href="#about">About</a>
          <a className={classNames('px-2.5 text-header transition-all duration-200 hover:text-endeavour', {'text-white': !isScroll, 'text-black': isScroll})} href="#venue">Venue</a>
          <a className={classNames('px-2.5 text-header transition-all duration-200 hover:text-endeavour', {'text-white': !isScroll, 'text-black': isScroll})} href="#event">Event</a>
          <a className={classNames('px-2.5 text-header transition-all duration-200 hover:text-endeavour', {'text-white': !isScroll, 'text-black': isScroll})} href={link?.career}>Career</a>
          <a className={classNames('px-2.5 text-header transition-all duration-200 hover:text-endeavour', {'text-white': !isScroll, 'text-black': isScroll})} href={link?.contact}>Contact</a>
        </div>
        <div className="md:hidden scale-125" onClick={() => setShowMenu(!showMenu)}>
          {showMenu ? <CloseIcon /> : <HamburgerIcon color={isScroll ? 'black' : 'white'} />}
        </div>
      </div>
      {showMenu && (
        <div className={classNames('absolute left-0 bg-white transition-all duration-200 w-full px-6 h-screen', {'top-[79px]': isScroll, 'top-[111px]': !isScroll})}>
          <a className="text-lg my-4 block flex items-center" href="#home" onClick={() => setShowMenu(false)}>
            <p>Home</p>
            <ChevronRight className="ml-2" color="#007aff" />
          </a>
          <a className="text-lg my-4 block flex items-center" href="#about" onClick={() => setShowMenu(false)}>
            <p>About</p>
            <ChevronRight className="ml-2" color="#007aff" />
          </a>
          <a className="text-lg my-4 block flex items-center" href="#venue" onClick={() => setShowMenu(false)}>
            <p>Venue</p>
            <ChevronRight className="ml-2" color="#007aff" />
          </a>
          <a className="text-lg my-4 block flex items-center" href="#event" onClick={() => setShowMenu(false)}>
            <p>Event</p>
            <ChevronRight className="ml-2" color="#007aff" />
          </a>
          <a className="text-lg my-4 block flex items-center" href={link?.career} onClick={() => setShowMenu(false)}>
            <p>Career</p>
            <ChevronRight className="ml-2" color="#007aff" />
          </a>
          <a className="text-lg my-4 block flex items-center" href={link?.contact} onClick={() => setShowMenu(false)}>
            <p>Contact</p>
            <ChevronRight className="ml-2" color="#007aff" />
          </a>
        </div>
      )}
    </header>
  );
};

export default Header;
