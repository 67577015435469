import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={12}
    height={12}
    fill="none"
    {...props}
  >
    <path fill="#fff" fillOpacity={0.01} d="M0 0h12v12H0z" />
    <path
      stroke={props.color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m3 10 4-4.014L3 2"
    />
  </svg>
)
export default SvgComponent
