import * as React from "react"
const IconTiktok = ({color, ...props}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    {...props}
  >
    <path
      fill={color || "#fff"}
      d="M14.443.023C16.171 0 17.885.01 19.6 0c.105 1.753.83 3.54 2.308 4.778 1.477 1.272 3.56 1.856 5.592 2.051v4.618c-1.9-.058-3.812-.401-5.54-1.112-.751-.298-1.45-.676-2.136-1.065-.013 3.346.013 6.691-.026 10.026a8.03 8.03 0 0 1-1.78 4.514c-1.729 2.2-4.722 3.632-7.795 3.678-1.886.092-3.772-.355-5.381-1.18C2.177 24.945.305 22.447.028 19.765a18.46 18.46 0 0 1-.014-1.707c.238-2.177 1.478-4.262 3.403-5.683 2.19-1.65 5.25-2.44 8.111-1.971.027 1.696-.053 3.392-.053 5.088-1.305-.367-2.835-.264-3.983.423-.83.47-1.463 1.192-1.793 2.006-.277.584-.198 1.226-.185 1.844.317 1.88 2.4 3.46 4.616 3.289 1.477-.012 2.889-.756 3.654-1.845.25-.378.527-.768.54-1.215.132-2.05.08-4.09.093-6.141.013-4.618-.014-9.224.026-13.83Z"
    />
  </svg>
)
export default IconTiktok
