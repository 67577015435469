import React, { useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { AnimatePresence, motion } from "framer-motion";
import { isEmpty } from 'lodash';
import classNames from 'classnames';

const BottomSheet = ({title, description, backgroundImage, visible, buttonConfig = {}, setVisible, showBackgroundText = false}) => {
  useEffect(() => {
    if (visible) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }
  }, [visible]);

  const onClickBackDrop = event => {
    if(event.target === event.currentTarget) {
      setVisible(false);
    }
  };

  if (!visible) return <></>;

  return (
    <div className='fixed top-0 left-0 z-50 w-full h-full' style={{background: 'rgba(0, 0, 0, 0.7)'}} onClick={onClickBackDrop}>
      <AnimatePresence>
        <motion.div animate={{y: 1, opacity: 1}} initial={{y: '100vh'}} transition={{ duration: 0.5 }} className='fixed bottom-0 left-0 md:left-[2vw] w-full rounded-t-[62px] pt-[188px] px-6 overflow-hidden pb-[10vh] md:w-[96vw]'>
          <div className='relative z-40'>
            <div className="text-white text-center text-[40px] font-bold leading-[58px]">{title}</div>
            <div className='max-w-[909px] mx-auto rounded-xl p-[10px] mt-[33px]' style={{background: showBackgroundText ? 'rgba(255, 255, 255, 0.62)' : 'transparent'}}>
              <div className={classNames('text-center text-2xl leading-9 font-extralight', {'text-black': showBackgroundText, 'text-white': !showBackgroundText})}>{description}</div>
            </div>
            {!isEmpty(buttonConfig) && (
              <div className='flex flex-row items-center justify-center mt-[38px]'>
                <div className="text-white text-2xl font-extralight leading-9">{buttonConfig.label}</div>
                <a href={buttonConfig.url}>
                  <button className="w-[70px] h-[29px] p-2.5 bg-neutral-300 rounded-[7px] justify-center items-center gap-2.5 inline-flex ml-2">
                    <div className="text-center text-white text-2xl font-bold leading-9">{buttonConfig.textButton}</div>
                  </button>
                </a>
              </div>
            )}
          </div>
          <LazyLoadImage
            alt="background"
            src={backgroundImage}
            className="absolute top-0 left-0 w-full h-full object-cover z-30"
            preventDragHandler={(e) => {
              e.preventDefault();
            }}
          />
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default BottomSheet;
