import React from "react";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
import ArrowIosRight from "./ArrowIosRight";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BASE_URL from "../Utils/Url";

const Card = React.memo(function (props) {
  const { data, dataIndex } = props;
  const { attributes } = data?.[dataIndex] || {};

  return (
    <div className="w-full h-[550px] select-none rounded-3xl overflow-hidden relative">
      <LazyLoadImage
        alt={attributes?.name}
        className="w-full h-full object-cover"
        draggable={false}
        src={`${BASE_URL}${attributes?.image?.data?.attributes?.url}`}
      />
      <div className="absolute left-0 bottom-0 p-3.5 pt-0 bg-black bg-opacity-60 backdrop-blur-[50px] w-full flex flex-col justify-center">
        <div className="text-white text-[36px] font-bold">{attributes?.name}</div>
        {attributes?.description && <div className="w-[310px] text-white text-[16px]">{attributes?.description}</div>}
      </div>
    </div>
  );
});

function Carousel({data}) {
  const ref = React.useRef();
  return (
    <div className="w-full relative">
      <ResponsiveContainer
        carouselRef={ref}
        render={(parentWidth, carouselRef) => {
          const list = (data || []);

          let currentVisibleSlide = list.length <= 3 ? 3 : 5;
          if (parentWidth <= 1440) currentVisibleSlide = 3;
          if (parentWidth <= 1080) currentVisibleSlide = 1;
          if (list.length === 1) {
            currentVisibleSlide = 1
          }

          return (
            <StackedCarousel
              ref={carouselRef}
              slideComponent={Card}
              slideWidth={parentWidth < 800 ? parentWidth - 40 : 550}
              carouselWidth={parentWidth}
              data={list}
              currentVisibleSlide={currentVisibleSlide}
              maxVisibleSlide={currentVisibleSlide}
              useGrabCursor
            />
          );
        }}
      />
      <>
        <div
          className="cursor-pointer"
          style={{ position: "absolute", top: "40%", left: 10, zIndex: 10 }}
          size="small"
          color="primary"
          onClick={() => {
            ref.current?.goBack();
          }}
        >
          <ArrowIosRight className="rotate-180" />
        </div>
        <div
          className="cursor-pointer"
          style={{ position: "absolute", top: "40%", right: 10, zIndex: 10 }}
          size="small"
          color="primary"
          onClick={() => {
            ref.current?.goNext(6);
          }}
        >
          <ArrowIosRight />
        </div>
      </>
    </div>
  );
}

export default Carousel;