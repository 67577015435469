import React, { useEffect, useState } from "react";
import "swiper/css";
import "swiper/css/effect-creative";
import "./App.css";
import Header from "./Components/Header";
import Carousel from "./Components/Carousel";
import PosterSlider from "./Components/PosterSlider";
import IconLinktree from "./Components/IconLinktree";
import IconInstagram from "./Components/IconInstagram";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BottomSheet from "./Components/BottomSheet";
import IconTiktok from "./Components/IconTiktok";
import { Helmet } from "react-helmet";
import axios from "axios";
import BASE_URL from "./Utils/Url";

const App = () => {
  const [isVisibleEvent, setIsVisibleEvent] = useState(false);
  const [isVisibleSpecialOfferWedding, setIsVisibleSpecialOfferWedding] = useState(false);
  const [isVisibleSpecialOfferPrivateEvent, setIsVisibleSpecialOfferPrivateEvent] = useState(false);
  const [isVisibleSpecialOfferWorkingSpace, setIsVisibleSpecialOfferWorkingSpace] = useState(false);
  const [link, setLink] = useState({
    career: '',
    contact: '',
    reserve_your_table: '',
    email: '',
    tiktok: '',
    instagram: '',
    createdAt: '',
    updatedAt: '',
    publishedAt: '',
    your_event: '',
});
const [loadingMenuHighlights, setLoadingMenuHighlights] = useState(true);
const [menuHighlights, setMenuHighlights] = useState([]);
const [upcomingEvents, setUpcomingEvents] = useState([]);

  useEffect(() => {
    axios.get(`${BASE_URL}/api/url`)
      .then(({data}) => {
        setLink(data?.data?.attributes);
      })
      .catch(() => {})
      .finally(() => {});

    setLoadingMenuHighlights(true);
    axios.get(`${BASE_URL}/api/menu-highlights?pagination[limit]=100&populate=*`)
      .then(({data}) => {
        setMenuHighlights(data?.data || []);
      })
      .catch(() => {})
      .finally(() => {
        setLoadingMenuHighlights(false);
      })
    
    axios.get(`${BASE_URL}/api/upcoming-events?pagination[limit]=100&populate=*&sort=id:desc`)
      .then(({data}) => {
        setUpcomingEvents(data?.data);
      })
      .catch(() => {})
      .finally(() => {});
  }, []);

  return (
    <>
      <Helmet>
        <title>Dimigo - Where the Beach Comes to the City!</title>
        <meta
          name="description"
          content="Discover a slice of paradise nestled right in the heart of the city at Dimigo, your ultimate beach-themed bar & resto experience. Escape the hustle and bustle of urban life as you step into our sun-kissed oasis, designed to transport you to the serene ambiance of a tropical beach. At Dimigo, we believe in bringing the best of both worlds together – the vibrant energy of the city and the relaxing vibes of the seaside. Imagine sinking your toes into warm sand, feeling the gentle ocean breeze, and indulging in delicious treats, all without having to leave the city limits."
        />
        <meta
          name="keywords"
          content="Cafe hits medan, cafe nongkrong medan, cafe estetik, dimigo, dimigo medan, medan, cafe, ngopi, nongrkong, kopi medan, pool, kopi, warkop, kafe, kafe medan"
        />
      </Helmet>
      <Header link={link} />
      <div className="relative w-full h-screen" id="home">
        <LazyLoadImage alt="Banner" src="/assets/banner.png" className="w-full h-full object-cover absolute top-0 left-0" />
        <div className="container py-28 relative px-6">
          <p className="mt-11 text-banner max-w-2xl">A Slice of Paradise in the Heart of the City</p>
        </div>
      </div>
      <div className="bg-pampas" id="about">
        <div className="container px-6 py-20">
          <h1 className="text-center">About Dimigo</h1>
          <p className="text-xl italic text-center my-7">Where the Beach Comes to the City!</p>
          <p className="text-center text-lg font-extralight">Discover a slice of paradise nestled right in the heart of the city at Dimigo, your ultimate beach-themed bar & resto experience. Escape the hustle and bustle of urban life as you step into our sun-kissed oasis, designed to transport you to the serene ambiance of a tropical beach.<br />At Dimigo, we believe in bringing the best of both worlds together – the vibrant energy of the city and the relaxing vibes of the seaside. Imagine sinking your toes into warm sand, feeling the gentle ocean breeze, and indulging in delicious treats, all without having to leave the city limits.</p>
          <LazyLoadImage
            alt="logo"
            src="/assets/logo-blue.png"
            className="h-10 object-contain mx-auto mt-7"
          />
        </div>
      </div>
      <div className="relative">
        <LazyLoadImage
          alt="Food & Beverage Background"
          src="/assets/background-section-3.png"
          className="absolute top-0 left-0 w-full h-full object-cover"
        />
        <div className="container relative py-20 px-6">
          <div className="md:hidden">
            <LazyLoadImage alt="Food & Beverage" className="w-full" src="/assets/food-and-beverage-1.png" />
            <div className="bg-mongoose p-4">
              <div className="text-sky-700 text-[32px] font-bold leading-10">Food & Beverage</div>
              <div className="text-black text-[16px] leading-7">At Dimigo, you'll find a carefully crafted menu that captures the essence of beachside dining. Our talented culinary team combines fresh, locally sourced ingredients with creative flair, bringing you a delightful array of flavors that will tantalize your taste buds. From mouthwatering local dishes to refreshing and tropical fruit smoothies, we have something to satisfy every craving.</div>
            </div>
          </div>
          <div className="w-[801px] h-[360px] relative group hidden md:block">
            <LazyLoadImage alt="Food & Beverage" className="w-[250px] h-[360px] left-0 top-0 absolute" src="/assets/food-and-beverage-1.png" />
            <LazyLoadImage alt="Food & Beverage" className="w-[250px] h-[360px] left-0 top-0 absolute group-hover:opacity-100 opacity-0 transition-all duration-500" src="/assets/food-and-beverage-2.png" />
            <div className="w-[597px] h-[17px] left-[177px] top-[10px] absolute bg-zinc-300 shadow group-hover:left-[250px] group-hover:top-[54px] group-hover:h-[253px] transition-all duration-500 group-hover:bg-mongoose" />
            <LazyLoadImage alt="flower" className="w-[114.46px] h-[116.05px] left-[692.03px] top-[154px] absolute origin-top-left rotate-[31.15deg] opacity-0 group-hover:opacity-100 transition-all duration-500" src="/assets/flower.png" />
            <LazyLoadImage alt="flower" className="w-[114.46px] h-[116.05px] left-[310.03px] top-[58px] absolute origin-top-left rotate-[31.15deg] opacity-0 group-hover:opacity-100 transition-all duration-500" src="/assets/flower.png" />
            <div className="left-[285px] top-[52px] absolute flex-col justify-start items-start gap-[17px] inline-flex">
              <div className="w-[281px] h-10 text-sky-700 text-[32px] font-bold leading-10">Food & Beverage</div>
              <div className="w-[489px] h-[207px] text-black text-[16px] leading-7 opacity-0 group-hover:opacity-100 transition-all duration-500">At Dimigo, you'll find a carefully crafted menu that captures the essence of beachside dining. Our talented culinary team combines fresh, locally sourced ingredients with creative flair, bringing you a delightful array of flavors that will tantalize your taste buds. From mouthwatering local dishes to refreshing and tropical fruit smoothies, we have something to satisfy every craving.</div>
            </div>
          </div>
        </div>
      </div>
      <div className="md:pb-[237px] pt-[96px]" id="menu">
        <div className="text-black text-[40px] font-bold leading-10 text-center">Menu Highlights</div>
        <div className="text-mongoose text-[28px] font-light text-center mb-[41px]" style={{letterSpacing: 6}}>NO DIMIGO NO PARTY</div>
        {!loadingMenuHighlights && menuHighlights?.length > 0 && (
          <Carousel data={menuHighlights} />
        )}
      </div>
      <div className="relative" id="venue">
        <LazyLoadImage
          alt="Background Section"
          src="/assets/background-section-5.png"
          className="absolute top-0 left-0 w-full h-full object-cover hidden md:block"
        />
        <div className="container py-[102px]">
          <div className="md:hidden relative">
            <LazyLoadImage alt="Food & Beverage" className="w-full" src="/assets/swimming-pool-1.png" />
            <div className="bg-mongoose p-4">
              <div className="text-sky-700 text-[32px] font-bold leading-10">Swimming Pool</div>
              <div className="text-black text-[16px] leading-7">Get ready to make a splash at our unforgettable swimming pool party!</div>
            </div>
          </div>
          <div className="flex-col items-end hidden md:flex">
            <div className="w-[801px] h-[360px] relative group">
              <div className="w-[250px] h-[360px] right-0 top-0 absolute group-hover:w-[411px] transition-all duration-500">
                <LazyLoadImage className="w-full h-full transition-all duration-500 object-cover absolute group-hover:opacity-0" src="/assets/swimming-pool-1.png" />
                <LazyLoadImage className="w-full h-full transition-all duration-500 object-cover absolute opacity-0 group-hover:opacity-100" src="/assets/swimming-pool-2.png" />
              </div>
              <div className="w-[597px] h-[17px] left-[27px] top-[10px] absolute bg-zinc-300 group-hover:bg-mongoose shadow group-hover:w-[375px] group-hover:h-[121px] group-hover:left-[15px] group-hover:top-[57px] transition-all duration-500" />
              <div className="left-[275px] top-[39px] absolute flex-col justify-start items-start gap-[17px] inline-flex group-hover:opacity-0 transition-all duration-500">
                <div className="w-[257px] h-10 text-sky-700 text-[32px] font-bold leading-10">Swimming Pool</div>
              </div>
              <div className="h-[113px] left-[24px] top-[59px] absolute flex-col justify-start items-start gap-[17px] inline-flex opacity-0 group-hover:opacity-100 transition-all duration-500">
                <div className="w-[257px] h-10 text-sky-700 text-[32px] font-bold leading-10">Swimming Pool</div>
                <div className="w-[363px] text-black text-[16px] leading-7">Get ready to make a splash at our unforgettable swimming pool party!</div>
              </div>
            </div>
          </div>
          <div className="md:hidden relative mt-[102px]">
            <LazyLoadImage alt="Food & Beverage" className="w-full" src="/assets/garden-1.png" />
            <div className="bg-mongoose p-4">
              <div className="text-sky-700 text-[32px] font-bold leading-10">Garden</div>
              <div className="text-black text-[16px] leading-7">Introducing our beach-theme garden, where coastal vibes and tranquil relaxation merge into a captivating oasis</div>
            </div>
          </div>
          <div className="w-[801px] h-[360px] relative group mt-[37px] hidden md:block">
            <div className="w-[250px] h-[360px] left-0 top-0 absolute group-hover:w-[411px] transition-all duration-500">
              <LazyLoadImage className="w-full h-full transition-all duration-500 object-cover absolute group-hover:opacity-0" src="/assets/garden-1.png" />
              <LazyLoadImage className="w-full h-full transition-all duration-500 object-cover absolute opacity-0 group-hover:opacity-100" src="/assets/garden-2.png" />
            </div>
            <div className="w-[597px] h-[17px] left-[177px] top-[10px] absolute bg-zinc-300 group-hover:bg-mongoose shadow group-hover:w-[375px] group-hover:h-[141px] group-hover:left-[411px] group-hover:top-[57px] transition-all duration-500" />
            <div className="left-[275px] top-[39px] absolute flex-col justify-start items-start gap-[17px] inline-flex group-hover:opacity-0 transition-all duration-500">
              <div className="w-[124px] h-10 text-sky-700 text-[32px] font-bold leading-10">Garden</div>
            </div>
            <div className="w-[363px] h-[141px] left-[417px] top-[57px] absolute opacity-0 group-hover:opacity-100 transition-all duration-500">
              <div className="w-[130px] h-10 left-0 top-0 absolute text-sky-700 text-[32px] font-bold leading-10">Garden</div>
              <div className="w-[363px] left-0 top-[57px] absolute text-black text-[16px] leading-7">Introducing our beach-theme garden, where coastal vibes and tranquil relaxation merge into a captivating oasis</div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-[119px] pb-[59px] relative" id="event">
        <LazyLoadImage
          alt="Food & Beverage Background"
          src="/assets/background-section-6.png"
          className="absolute top-0 left-0 w-full h-full object-cover"
        />
        <div className="relative">
          <div className="container px-6">
            <div className="text-stone-200 text-[40px] font-bold leading-10 mb-[47px]">Upcoming Events</div>
            <LazyLoadImage alt="no dimigo no party" src="/assets/no-dimigo-no-party.png" />
          </div>
          <div className="lg:absolute top-0 right-0 lg:w-[50vw] mt-10 lg:mt-0">
            <PosterSlider data={upcomingEvents} />
          </div>
          <div className="flex justify-center">
            <button className="p-2.5 rounded-[32px] mt-10 md:mt-[148px] background-button group transition-all duration-500" onClick={() => setIsVisibleEvent(true)}>
              <div className="text-stone-500 text-[24px] font-semibold leading-10 group-hover:text-white transition-all duration-500">Your Event</div>
            </button>
          </div>
        </div>
      </div>
      <div className="pt-[24px] pb-[107px] container px-6">
        <div className="h-11 text-black text-[40px] font-bold text-center mb-[39px]">Special Offers</div>
        <div className="flex flex-col md:flex-row justify-between gap-4">
          <div className="bg-white rounded-xl shadow-lg p-[10px] pb-[18px] hover:scale-110 transition-all duration-500 cursor-pointer" onClick={() => setIsVisibleSpecialOfferWedding(true)}>
            <LazyLoadImage className="rounded-md md:w-[335px] md:h-[234px] w-full" src="/assets/wedding.png" alt="wedding" />
            <div className="text-zinc-800 text-[36px] font-normal text-center">WEDDING</div>
          </div>
          <div className="bg-white rounded-xl shadow-lg p-[10px] pb-[18px] hover:scale-110 transition-all duration-500 cursor-pointer" onClick={() => setIsVisibleSpecialOfferPrivateEvent(true)}>
            <LazyLoadImage className="rounded-md md:w-[335px] md:h-[234px] w-full" src="/assets/private-event.png" alt="private-event" />
            <div className="text-zinc-800 text-[36px] font-normal text-center">PRIVATE EVENT</div>
          </div>
          <div className="bg-white rounded-xl shadow-lg p-[10px] pb-[18px] hover:scale-110 transition-all duration-500 cursor-pointer" onClick={() => setIsVisibleSpecialOfferWorkingSpace(true)}>
            <LazyLoadImage className="rounded-md md:w-[335px] md:h-[234px] w-full" src="/assets/working-space.png" alt="working-space" />
            <div className="text-zinc-800 text-[36px] font-normal text-center">WORKING SPACE</div>
          </div>
        </div>
      </div>
      <div className="bg-mongoose pt-[24px] pb-[15px]">
        <div className="container px-6">
          <div class="grid gap-4 grid-cols-1 md:grid-cols-3">
            <div className="md:block hidden" />
            <div className="flex justify-center">
              <a href={link?.reserve_your_table}>
                <button className="p-2.5 rounded-[32px] background-button group transition-all duration-500">
                  <div className="text-stone-500 text-[24px] font-semibold leading-10 group-hover:text-white transition-all duration-500">Reserve your table</div>
                </button>
              </a>
            </div>
          </div>
          <div className="flex flex-col-reverse gap-y-20 md:flex-row items-center justify-between mt-[80px]">
            <div className="flex-1 flex flex-col items-center">
              <p className="text-white text-[20px] font-semibold leading-7">Contact Us</p>
              <a href={`mailto:${link.email}`} className="text-white text-[18px] font-normal mt-[20px] mb-[24px] hover:text-endeavour transition-all duration-500">{link?.email}</a>
              <div className="flex flex-row items-center gap-[20px]">
                <a href={link?.tiktok} className="relative w-[28px] h-[28px] group">
                  <IconTiktok color="#fff" className="absolute top-0 left-0 group-hover:opacity-0 transition-all duration-500" />
                  <IconTiktok color="#0156B3" className="absolute top-0 left-0 opacity-0 group-hover:opacity-100 transition-all duration-500" />
                </a>
                <a href={link?.instagram} className="relative w-[28px] h-[28px] group">
                  <IconInstagram color="#fff" className="absolute top-0 left-0 group-hover:opacity-0 transition-all duration-500" />
                  <IconInstagram color="#0156B3" className="absolute top-0 left-0 opacity-0 group-hover:opacity-100 transition-all duration-500" />
                </a>
                <a href="https://linktr.ee/dimigo" className="relative w-[28px] h-[28px] group">
                  <IconLinktree color="#fff" className="absolute top-0 left-0 group-hover:opacity-0 transition-all duration-500" />
                  <IconLinktree color="#0156B3" className="absolute top-0 left-0 opacity-0 group-hover:opacity-100 transition-all duration-500" />
                </a>
              </div>
            </div>
            <div className="flex-1 flex flex-col items-center">
              <div className="text-white text-[20px] font-semibold leading-7">Address</div>
              <div className="text-center text-white text-[18px] font-normal mt-[20px] mb-[24px]">Jl. Tapian Nauli Pasar 3 Ringroad Medan Sunggal</div>
              <a href="https://goo.gl/maps/WBrusHMgi3HvxaYN6" className="text-white text-[18px] font-normal hover:text-endeavour transition-all duration-500">Find Us on Google Map</a>
            </div>
            <div className="flex-1 flex items-center justify-center">
              <LazyLoadImage alt="dimigo-group" src="/assets/dimigo-group.png" className="mt-8 mx-auto md:mx-0" />
            </div>
          </div>
          <div className="flex justify-center mt-[66px]">
            <a href="https://creatica.id/">
              <LazyLoadImage alt="tob logo" src="/assets/powered-by.png" width={120} />
            </a>
          </div>
        </div>
      </div>
      <BottomSheet
        title="Your Event"
        description="In addition to our delectable food and inviting ambiance, Dimigo also hosts a variety of exciting events. Join us for live music nights, beach-themed parties, and community gatherings where you can connect with fellow beach enthusiasts in the heart of the city."
        backgroundImage="/assets/event.png"
        buttonConfig={{
          label: 'Download our functions kit',
          textButton: 'HERE',
          url: link?.your_event,
        }}
        visible={isVisibleEvent}
        setVisible={setIsVisibleEvent}
      />
      <BottomSheet
        title="Wedding"
        description="Exchange your vows in a romantic seaside setting at Dimigo, creating memories that will last a lifetime."
        backgroundImage="/assets/special-offer-wedding.png"
        visible={isVisibleSpecialOfferWedding}
        setVisible={setIsVisibleSpecialOfferWedding}
        showBackgroundText
      />
      <BottomSheet
        title="Private Event"
        description="Dimigo offers an exceptional location to host your event. From birthday parties to corporate events, private celebrations, and family gathering."
        backgroundImage="/assets/special-offer-private-event.png"
        visible={isVisibleSpecialOfferPrivateEvent}
        setVisible={setIsVisibleSpecialOfferPrivateEvent}
        showBackgroundText
      />
      <BottomSheet
        title="Coworking Space"
        description="Experience the ultimate work-life balance at the Dimigo's working space, where you can be productive while embracing the soothing beach vibes."
        backgroundImage="/assets/special-offer-working-space.png"
        visible={isVisibleSpecialOfferWorkingSpace}
        setVisible={setIsVisibleSpecialOfferWorkingSpace}
        showBackgroundText
      />
    </>
  );
};

export default App;
