import * as React from "react"
const SvgComponent = (props) => (
  <svg width={23} height={23} {...props}>
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth={2}
      d="m3 16.5 14-14M3 2.5l14 13.846"
    />
  </svg>
)
export default SvgComponent
