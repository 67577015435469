import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { EffectCreative } from "swiper";
import BASE_URL from "../Utils/Url";

const PosterSlider = ({data}) => {
  return (
    <div className="posters-slider overflow-visible w-full">
      <Swiper
        grabCursor={true}
        effect={"creative"}
        initialSlide={6}
        creativeEffect={{
          limitProgress: 3,
          prev: {
            shadow: true,
            translate: ["-15%", 0, -100],
          },
          next: {
            translate: ["200%", 0, 0],
          },
        }}
        modules={[EffectCreative]}
        className="mySwiper3"
      >
        {data?.map?.((item, index) => (
          <SwiperSlide key={index}>
            <LazyLoadImage alt="" src={`${BASE_URL}${item?.attributes?.image?.data?.attributes?.url}`} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default PosterSlider;
