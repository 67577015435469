import * as React from "react"
const IconInstagram = ({color, ...props}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    {...props}
  >
    <path
      fill={color || '#FFF'}
      fillRule="evenodd"
      d="M8.081.083C9.548.014 10.015 0 13.75 0c3.735 0 4.202.016 5.668.083 1.464.066 2.464.299 3.339.639a6.743 6.743 0 0 1 2.435 1.586 6.74 6.74 0 0 1 1.586 2.435c.34.875.572 1.875.64 3.338.067 1.467.082 1.934.082 5.669 0 3.735-.016 4.202-.082 5.669-.067 1.463-.3 2.463-.64 3.338a6.743 6.743 0 0 1-1.586 2.435 6.739 6.739 0 0 1-2.435 1.586c-.875.34-1.875.572-3.338.64-1.467.067-1.934.082-5.669.082-3.735 0-4.202-.016-5.669-.082-1.463-.067-2.463-.3-3.338-.64a6.745 6.745 0 0 1-2.435-1.586 6.739 6.739 0 0 1-1.586-2.435c-.34-.875-.572-1.875-.64-3.338C.016 17.952 0 17.485 0 13.75c0-3.735.016-4.202.083-5.668.066-1.464.299-2.464.639-3.339a6.745 6.745 0 0 1 1.586-2.435A6.74 6.74 0 0 1 4.743.722C5.618.382 6.618.15 8.081.082Zm11.226 2.474c-1.45-.065-1.886-.08-5.557-.08-3.671 0-4.107.015-5.557.08-1.34.062-2.068.285-2.552.474-.642.25-1.1.547-1.582 1.028-.48.482-.779.94-1.028 1.582-.189.484-.412 1.212-.474 2.552-.065 1.45-.08 1.886-.08 5.557 0 3.671.015 4.107.08 5.556.062 1.341.285 2.07.474 2.553.22.598.572 1.138 1.028 1.582.444.456.984.808 1.582 1.028.484.189 1.212.412 2.552.473 1.45.066 1.885.08 5.557.08 3.672 0 4.107-.014 5.556-.08 1.341-.06 2.07-.284 2.553-.473a4.257 4.257 0 0 0 1.582-1.028 4.263 4.263 0 0 0 1.028-1.581c.189-.485.412-1.213.473-2.553.066-1.45.08-1.886.08-5.557 0-3.671-.014-4.107-.08-5.557-.06-1.34-.284-2.068-.473-2.552a4.256 4.256 0 0 0-1.028-1.582c-.482-.48-.94-.779-1.581-1.028-.485-.189-1.213-.412-2.553-.474ZM11.994 17.99a4.587 4.587 0 0 0 5-7.483 4.588 4.588 0 1 0-5 7.483ZM8.753 8.753a7.068 7.068 0 1 1 9.995 9.994 7.068 7.068 0 0 1-9.995-9.994Zm13.632-1.018a1.67 1.67 0 1 0-2.363-2.362 1.67 1.67 0 0 0 2.363 2.362Z"
      clipRule="evenodd"
    />
  </svg>
)
export default IconInstagram
