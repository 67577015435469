import * as React from "react"
const ArrowIosRight = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={70}
    height={70}
    fill="none"
    {...props}
  >
    <path
      fill="#0156B3"
      fillRule="evenodd"
      d="M29.165 14.583a2.917 2.917 0 0 0-2.24 4.783L39.98 35.032l-12.585 15.64a2.918 2.918 0 0 0 4.547 3.657l14.081-17.5a2.918 2.918 0 0 0-.032-3.696l-14.583-17.5a2.914 2.914 0 0 0-2.243-1.05Z"
      clipRule="evenodd"
    />
    <mask
      id="a"
      width={21}
      height={42}
      x={26}
      y={14}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance",
      }}
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M29.165 14.583a2.917 2.917 0 0 0-2.24 4.783L39.98 35.032l-12.585 15.64a2.918 2.918 0 0 0 4.547 3.657l14.081-17.5a2.918 2.918 0 0 0-.032-3.696l-14.583-17.5a2.914 2.914 0 0 0-2.243-1.05Z"
        clipRule="evenodd"
      />
    </mask>
  </svg>
);

export default ArrowIosRight
