import * as React from "react"
const SvgComponent = (props) => (
  <svg width={23} height={23} {...props}>
    <path
      fill={props.color}
      stroke={props.color}
      strokeLinecap="round"
      strokeWidth={2}
      d="M2 2.5h18M2 9.423h18M2 16.346h18"
    />
  </svg>
)
export default SvgComponent
