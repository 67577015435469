import * as React from "react"
const IconLinktree = ({color, ...props}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    fill="none"
    {...props}
  >
    <path
      stroke={color || '#FFF'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m10.813 3.438-8.021 13.75h3.437v5.729h5.73v-5.73H9.667l4.583-8.02-3.437-5.73Z"
    />
    <path
      stroke={color || '#FFF'}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="m17.688 3.438 8.02 13.75h-3.437v5.729h-5.73v-5.73h2.292l-4.583-8.02 3.438-5.73Z"
    />
  </svg>
)
export default IconLinktree
